import React from "react";

export const InstagramIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9333 4.73333C15.8 1.8 14.2 0.2 11.2667 0.0666667C10.4667 0 10.2 0 8 0C5.8 0 5.53333 0 4.73333 0.0666667C1.8 0.2 0.2 1.8 0.0666667 4.73333C0 5.53333 0 5.8 0 8C0 10.2 0 10.4667 0.0666667 11.2667C0.2 14.2 1.8 15.8 4.73333 15.9333C5.53333 16 5.8 16 8 16C10.2 16 10.4667 16 11.2667 15.9333C14.2 15.8 15.8 14.2 15.9333 11.2667C16 10.4667 16 10.2 16 8C16 5.8 16 5.53333 15.9333 4.73333ZM7.93333 12.8C5.33333 12.8 3.26667 10.6667 3.26667 8.13333C3.26667 5.53333 5.4 3.46667 7.93333 3.46667C10.5333 3.46667 12.6 5.6 12.6 8.13333C12.6 10.6667 10.5333 12.8 7.93333 12.8ZM12.8 4.26667C12.2 4.26667 11.7333 3.8 11.7333 3.2C11.7333 2.6 12.2 2.13333 12.8 2.13333C13.4 2.13333 13.8667 2.6 13.8667 3.2C13.8667 3.8 13.4 4.26667 12.8 4.26667ZM7.93333 11.1333C6.26667 11.1333 4.86667 9.8 4.86667 8.06667C4.86667 6.4 6.2 5 7.93333 5C9.66667 5 11 6.33333 11 8.06667C10.9333 9.73333 9.6 11.1333 7.93333 11.1333Z"
      fill="#0043EA"
    />
  </svg>
);

export const TwitterIcon = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 1.86683C15.4 2.1335 14.8 2.3335 14.1333 2.40016C14.8 2.00016 15.3333 1.3335 15.6 0.600163C14.9333 1.00016 14.2667 1.26683 13.5333 1.40016C12.8667 0.733496 12 0.333496 11.0667 0.333496C8.93333 0.333496 7.4 2.3335 7.86667 4.3335C5.13333 4.20016 2.73333 2.9335 1.13333 0.933496C0.266667 2.40016 0.666667 4.3335 2.13333 5.3335C1.6 5.26683 1.06667 5.1335 0.666667 4.9335C0.6 6.46683 1.73333 7.86683 3.26667 8.20016C2.8 8.3335 2.26667 8.3335 1.8 8.26683C2.2 9.60016 3.4 10.5335 4.86667 10.5335C3.46667 11.6002 1.73333 12.0668 0 11.8668C1.46667 12.8002 3.2 13.3335 5 13.3335C11.0667 13.3335 14.5333 8.20016 14.3333 3.60016C15 3.1335 15.5333 2.5335 16 1.86683Z"
      fill="#0043EA"
    />
  </svg>
);
